import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import './services.css';
import servicesData from '../data/servicesData';

SwiperCore.use([Autoplay, Pagination]);

function Services({ refs }) {
  return (
    <section id="services" className="services bg-light" ref={refs.services}>
      <div className="container">
        <div
          className="row justify-content-center mb-5"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div className="col-md-7 text-center">
            <h2 className="scissors text-center">
              Services &amp; Pricing <br />
              <i className="fa fa-scissors" aria-hidden="true"></i>
            </h2>
            <p className="mb-5 lead">
              A list of our services, including haircuts, coloring, styling, and
              more, along with the corresponding prices. You can feel confident
              that you're getting the best value for your money (Price subjects
              to potential changes).
            </p>

            <div className="swiper-pagination"></div>
          </div>
        </div>

        <div className="row" data-aos="fade-up" data-aos-delay="200">
          <div className="col-12">
            <Swiper
              slidesPerView={'auto'}
              spaceBetween={20}
              speed={600}
              loop={true}
              autoplay={{ delay: 5000, disableOnInteraction: false }}
              pagination={{
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
                bulletclassName: 'swiper-pagination-bullet',
              }}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                480: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
              }}
            >
              {servicesData.map(item => (
                <SwiperSlide className="col-lg-4 col-sm-12" key={item.id}>
                  <div className="item-1 h">
                    <img src={item.imgUrl} alt="Image" className="img-fluid" />
                    <div className="item-1-contents">
                      <h3>{item.title}</h3>
                      <ul>
                        {item.services.map(service => (
                          <li
                            className="d-flex justify-content-between"
                            key={service.id}
                          >
                            <span>{service.name}</span>
                            <span className="price ml-auto">
                              {service.price}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
