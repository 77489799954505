import firstIcon from '../assets/salon/hair.png';
import secondIcon from '../assets/salon/women.png';
import thirdIcon from '../assets/salon/hair-cutting.png';
import fourthIcon from '../assets/salon/salon.png';
import fifthIcon from '../assets/salon/shampoo.png';
import sixthIcon from '../assets/salon/beauty-treatment.png';

const moreServicesData = [
  {
    id: 1,
    imgUrl: firstIcon,
    title: 'Coloring',
    content: `Choosing the best products with the best techniques, to create stunning looks just for you.`,
  },
  {
    id: 2,
    imgUrl: secondIcon,
    title: 'Foiling',
    content: `Unlock Radiant Beauty with Foiling Artistry, Where Every Strand Becomes a Shimmering Masterpiece`,
  },
  {
    id: 3,
    imgUrl: thirdIcon,
    title: 'Customised Design',
    content: `Our stylists will create looks that are custom tailored to suit your personality, lifestyle, and aesthetics.`,
  },
  {
    id: 4,
    imgUrl: fourthIcon,
    title: 'Quick Cut',
    content: `Look sharp in no time. Quick Cut Salon, where style meets speed`,
  },
  {
    id: 5,
    imgUrl: fifthIcon,
    title: 'Scalp Treatments',
    content: `Beautiful scalp, beautiful hair. For hair to look its best, pamper it from roots to ends.`,
  },
  {
    id: 6,
    imgUrl: sixthIcon,
    title: 'Bleaching',
    content: `Unleashing your inner glow, Looking to achieve a brighter, more youthful complexion`,
  },
];

export default moreServicesData;
