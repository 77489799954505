import React, { useState } from "react";
import emailjs from "emailjs-com";

function NewsletterForm() {
  const intialState = {
    email: "",
    result: "",
  };
  const [text, setText] = useState(intialState);

  const changeText = (e) => {
    const { name, value } = e.target;
    setText({ ...text, [name]: value, result: "" });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (text.email === "") {
      setText({ ...text, result: "incomplete" });
      return;
    }
    // show pending info while sending email
    setText({ ...text, result: "loading" });
    emailjs
      .sendForm(
        "service_t06c7pb",
        "template_yw7giy5",
        e.target,
        "8U3LR69gRVi5x3zMC"
      )
      .then(
        (result) => {
          setText({
            ...intialState,
            result: "success",
          });
        },
        (error) => {
          setText({
            ...intialState,
            result: "error",
          });
        }
      );
  };

  return (
    <form onSubmit={sendEmail} role="form">
      <input
        type="email"
        name="email"
        value={text.email}
        onChange={changeText}
        placeholder="Your Email"
        required
      />
      <input
        type="submit"
        value={
          text.result === "loading"
            ? "Loading"
            : text.result === "success"
            ? "Sent"
            : "Subscribe"
        }
      />
    </form>
  );
}

export default NewsletterForm;
