import React from 'react';
import './footer.css';
import logo from '../assets/logo_white.png';
import NewsletterForm from './NewsletterForm';

function Footer({ refs }) {
  const handlePageScroll = section => e => {
    e.preventDefault();
    window.scrollTo(
      0,
      refs[section].current.offsetTop - refs.header.current.offsetHeight + 1
    );
  };
  return (
    <footer id="footer" ref={refs.footer}>
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="footer-info">
                <a href="/" className="logo">
                  <img src={logo} alt="" className="img-fluid" />
                </a>
                <p>
                  We are passionate about hair styling and cutting and strongly
                  believe fabulous hair style changes to a better life. BUEN
                  hair salon offers contemporary hair service to all our
                  clients.
                </p>
                <div className="social-links mt-3">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/people/Buen-Hair/pfbid0jcuTUDWUtNJLF3agb7Qmkq5omJjatupqTwYNJxvr7V1F6nqp8XCX4sx2hshMXMZUl/"
                    className="facebook"
                  >
                    <i className="bx bxl-facebook"></i>
                  </a>
                  <a
                    target="_blank"
                    href="https://www.youtube.com/channel/UCxGddA8JiWXSuRj9GlINj6w"
                    className="instagram"
                  >
                    <i className="bx bxl-youtube"></i>
                  </a>
                  <a
                    target="_blank"
                    href="https://www.google.com/search?q=BUEN+Salon&stick=H4sIAAAAAAAA_-NgU1I1qDBLsjQ0NTS3NLFISzYxNTC3MqiwMDRLTDFNSks0sUg1MTY2X8TK5RTq6qcQnJiTnwcAzts3nTYAAAA&hl=en&mat=CcpVqaxg7zhxElcBpsFACzepYTX3AQrt9yQ1uu3HCbNiesKs2nX8yol1S-pwF5jNWIkUI5UnydGkwN4qpgFPL4NjE3xcsZl-4R0Zu0eo_H2P5o0M02o1RjUjphqFBqcyu3A&authuser=0"
                    className="google-plus"
                  >
                    <i className="bx bxl-google"></i>
                  </a>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/buen0411299998/"
                    className="linkedin"
                  >
                    <i className="bx bxl-instagram"></i>
                  </a>
                  <img
                    height="1"
                    width="1"
                    style={{ display: 'none' }}
                    src="https://www.facebook.com/tr?id=680584660624008&ev=PageView&noscript=1"
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <i className="bx bx-chevron-right"></i>{' '}
                  <a onClick={() => window.scrollTo(0, 0)}>Home</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{' '}
                  <a onClick={handlePageScroll('about')}>About us</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{' '}
                  <a onClick={handlePageScroll('services')}>
                    Services & Pricing
                  </a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{' '}
                  <a onClick={handlePageScroll('portfolio')}>Gallery</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{' '}
                  <a onClick={handlePageScroll('contact')}>Contact us</a>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li>
                  <i className="bx bx-chevron-right"></i>{' '}
                  <a onClick={handlePageScroll('moreServices')}>Hair Styling</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{' '}
                  <a onClick={handlePageScroll('moreServices')}>
                    Hair Scalp Caring
                  </a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{' '}
                  <a onClick={handlePageScroll('moreServices')}>Quick Cut</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{' '}
                  <a onClick={handlePageScroll('moreServices')}>
                    Coloring & Bleaching
                  </a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{' '}
                  <a onClick={handlePageScroll('moreServices')}>
                    Customised Design
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-lg-4 col-md-6 footer-newsletter">
              <h4>Our Newsletter</h4>
              <p>
                Stay up-to-date with the latest beauty trends, promotions, and
                insider tips by subscribing to our newsletter today!
              </p>
              <NewsletterForm />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="copyright">
          &copy; Copyright {new Date().getFullYear()}{' '}
          <strong>
            <span>BUEN Group</span>
          </strong>
          . All Rights Reserved
        </div>
        <div className="credits">
          Designed by{' '}
          <a target="_blank" href="https://www.dstudiotech.com/">
            DStudio Technology
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
