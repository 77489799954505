import React from "react";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Main from "../components/Main";
import BackToTop from "../components/BackToTop";
import Footer from "../components/Footer";

function Home({ scroll, refs }) {
  return (
    <>
      <Header scroll={scroll} refs={refs} />
      <Hero scroll={scroll} refs={refs} />
      <Main scroll={scroll} refs={refs} />
      <Footer refs={refs} />
      <BackToTop scroll={scroll} />
    </>
  );
}

export default Home;
