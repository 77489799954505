import React from "react";
import "./moreServices.css";
import moreServicesData from "../data/moreServicesData";

function MoreServices({ refs }) {
  return (
    <section
      id="more-services"
      className="more-services"
      ref={refs.moreServices}
    >
      <div className="container">
        <div
          className="row justify-content-center text-center"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div className="col-md-7 text-center mb-5">
            <h2 className="text-white scissors primary-color-icon text-center">
              More Services
              <br />
              <i className="fa fa-scissors" aria-hidden="true"></i>
            </h2>
            <p className="lead text-white">
              Comprehensive list of the solutions we offer to meet your unique
              needs. We understand that every client is different, which is why
              we take a personalized approach to our services.
            </p>
          </div>
        </div>
        <div className="row" data-aos="fade-up" data-aos-delay="200">
          {moreServicesData.map((item) => (
            <div className="col-lg-4 col-md-6 mb-4" key={item.id}>
              <div className="service-1">
                <span className="service-1-icon">
                  <img src={item.imgUrl} alt={item.title} />
                </span>
                <div className="service-1-contents">
                  <h3>{item.title}</h3>
                  <p>{item.content}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default MoreServices;
