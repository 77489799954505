import firstSlide from "../assets/hero/hero-bg-5.jpg";
import secondSlide from "../assets/hero/hero-bg-4.jpg";
import thirdSlide from "../assets/hero/hero-bg-3.jpg";

const heroIndicator = [
  {
    id: 1,
    status: "active",
  },
  {
    id: 2,
    status: "",
  },
  {
    id: 3,
    status: "",
  },
];

const heroData = [
  {
    id: 1,
    status: "active",
    imgSrc: firstSlide,
    title: "Unlock Your Beauty Potential",
    content: `Our experienced team of stylists is dedicated to providing you with the perfect look that suits your personal style.`,
    btnText: "Learn More",
  },
  {
    id: 2,
    status: "",
    imgSrc: secondSlide,
    title: "More Than Just A Hair Cut",
    content: `Whether you're looking for a simple trim, a dramatic new look, or just some pampering, we have a wide range of services to meet your needs.`,
    btnText: "Learn More",
  },
  {
    id: 3,
    status: "",
    imgSrc: thirdSlide,
    title: "Discover Your Perfect Look Today",
    content: `At our salon, we believe that beauty should be accessible to everyone, which is why we offer affordable pricing.`,
    btnText: "Learn More",
  },
];

export { heroIndicator, heroData };
