import React, { useState } from "react";
import emailjs from "emailjs-com";

function ContactForm() {
  const intialState = {
    fullname: "",
    email: "",
    subject: "",
    message: "",
    result: "",
  };
  const [text, setText] = useState(intialState);

  const changeText = (e) => {
    const { name, value } = e.target;
    setText({ ...text, [name]: value, result: "" });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (text.fullname === "" || text.email === "" || text.message === "") {
      setText({ ...text, result: "incomplete" });
      return;
    }
    // show pending info while sending email
    setText({ ...text, result: "loading" });
    emailjs
      .sendForm(
        "service_t06c7pb",
        "template_8ehnnmv",
        e.target,
        "8U3LR69gRVi5x3zMC"
      )
      .then(
        (result) => {
          setText({
            ...intialState,
            result: "success",
          });
        },
        (error) => {
          setText({
            ...intialState,
            result: "error",
          });
        }
      );
  };

  return (
    <form onSubmit={sendEmail} role="form" className="php-email-form">
      <div className="row">
        <div className="col form-group">
          <input
            type="text"
            name="fullname"
            value={text.fullname}
            onChange={changeText}
            className="form-control"
            id="name"
            placeholder="Your Name"
          />
        </div>
        <div className="col form-group">
          <input
            type="email"
            className="form-control"
            name="email"
            id="email"
            value={text.email}
            onChange={changeText}
            placeholder="Your Email"
          />
        </div>
      </div>
      <div className="form-group mt-3">
        <input
          type="text"
          className="form-control"
          name="subject"
          id="subject"
          value={text.subject}
          onChange={changeText}
          placeholder="Subject"
        />
      </div>
      <div className="form-group mt-3">
        <textarea
          className="form-control"
          name="message"
          value={text.message}
          onChange={changeText}
          rows="5"
          placeholder="Message"
        ></textarea>
      </div>
      <div className="my-3">
        {text.result === "loading" && <div className="loading">Loading</div>}
        {text.result === "error" && (
          <div className="error-message">
            Sorry, message sent failed, please try again later
          </div>
        )}
        {text.result === "incomplete" && (
          <div className="error-message">Please complete all above details</div>
        )}
        {text.result === "success" && (
          <div className="sent-message">
            Your message has been sent. Thank you!
          </div>
        )}
      </div>
      {text.result !== "loading" && (
        <div className="text-center">
          <button type="submit">Send Message</button>
        </div>
      )}
    </form>
  );
}

export default ContactForm;
