import firstSlide from '../assets/price/price-1.jpg';
import secondSlide from '../assets/price/price-8.jpg';
import thirdSlide from '../assets/price/price-3.jpg';
import fourthSlide from '../assets/price/price-4.jpg';
import fifthSlide from '../assets/price/price-5.jpg';
import sixthSlide from '../assets/price/price-6.jpg';
import seventhSlide from '../assets/price/price-7.jpg';

const servicesData = [
  {
    id: 1,
    imgUrl: fourthSlide,
    title: 'Cut',
    services: [
      { id: 1, name: "Ladies' Cut", price: '$59.00' },
      { id: 2, name: "Ladies' Styling", price: '$49.00' },
      { id: 3, name: "Men's Cut", price: '$39.00' },
      { id: 4, name: 'Cut under 18 ', price: '$19.00' },
    ],
  },
  {
    id: 2,
    imgUrl: sixthSlide,
    title: 'HAIR & SCALP TREATMENTS',
    services: [
      { id: 1, name: 'Basin Treatment (S/M)', price: '$19.00' },
      { id: 2, name: 'Basin Treatment (L/XL)', price: '$29.00' },
      { id: 3, name: 'Hair and Scalp (S/M)', price: '$119.00' },
      { id: 4, name: 'Hair and Scalp (L/XL)', price: '$159.00' },
    ],
  },
  {
    id: 3,
    imgUrl: thirdSlide,
    title: 'KERATIN TREATMENT',
    services: [
      { id: 1, name: 'Keratin Treatment (S/M)', price: '$229.00' },
      { id: 2, name: 'Keratin Treatment (L)', price: '$269.00' },
      { id: 3, name: 'Keratin Treatment (XL)', price: '$299.00' },
    ],
  },
  {
    id: 4,
    imgUrl: firstSlide,
    title: 'COLORING',
    services: [
      { id: 1, name: 'Retouch (S/M - 4 weeks)', price: '$69.00' },
      { id: 2, name: 'Retouch (L - 6 weeks)', price: '$79.00' },
      { id: 3, name: 'Retouch (XL - 8 weeks)', price: '$89.00' },
      { id: 4, name: 'Global Permanent (S/M - 4 weeks)', price: '$99.00' },
      { id: 5, name: 'Global Permanent (L - 6 weeks)', price: '$129.00' },
      { id: 6, name: 'Global Permanent (XL - 8 weeks)', price: '$159.00' },
    ],
  },
  {
    id: 5,
    imgUrl: fifthSlide,
    title: 'BLEACHING',
    services: [
      { id: 1, name: 'Regrowth Scalp Bleach (S/M)', price: '$79.00' },
      { id: 2, name: 'Regrowth Scalp Bleach (L)', price: '$99.00' },
      { id: 3, name: 'Regrowth Scalp Bleach (XL)', price: '$119.00' },
      { id: 4, name: 'Full head (S/M)', price: '$119.00' },
      { id: 5, name: 'Full head (L)', price: '$169.00' },
      { id: 6, name: 'Full head (XL)', price: '$229.00' },
    ],
  },
  {
    id: 6,
    imgUrl: secondSlide,
    title: 'FOILING',
    services: [
      { id: 1, name: 'Full head (S/M)', price: '$169.00' },
      { id: 2, name: 'Full head (L)', price: '$189.00' },
      { id: 3, name: 'Full head (XL)', price: '$219.00' },
      { id: 4, name: 'Half head (S/M)', price: '$119.00' },
      { id: 5, name: 'Half head (L)', price: '$139.00' },
      { id: 6, name: 'Half head (XL)', price: '$169.00' },
      { id: 7, name: 'Per Foil', price: '$12.00' },
    ],
  },
  {
    id: 7,
    imgUrl: seventhSlide,
    title: 'TONING & PERMING',
    services: [
      { id: 1, name: 'Toning (S/M)', price: '$29.00' },
      { id: 2, name: 'Toning (L)', price: '$39.00' },
      { id: 3, name: 'Toning (XL)', price: '$49.00' },
      { id: 4, name: 'Perming (S/M)', price: '$119.00' },
      { id: 5, name: 'Perming (L)', price: '$159.00' },
      { id: 6, name: 'Perming (XL)', price: '$199.00' },
      { id: 7, name: 'Colour Correction', price: 'Per Service' },
      { id: 8, name: 'Extensions', price: 'POA' },
    ],
  },
];

export default servicesData;
