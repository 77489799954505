import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "font-awesome/css/font-awesome.min.css";
import "remixicon/fonts/remixicon.css";
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useState, useEffect, useRef } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import NotFoundPage from "./pages/NotFoundPage";
import ComingSoonPage from "./pages/ComingSoonPage";

function App() {
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
  }, [scroll]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY);
    });
    return () => {
      window.removeEventListener("scroll", () => {
        setScroll(window.scrollY);
      });
    };
  }, [scroll]);

  const headerRef = useRef();
  const footerRef = useRef();
  const preloaderRef = useRef();
  const heroRef = useRef();
  const aboutRef = useRef();
  const servicesRef = useRef();
  const portfolioRef = useRef();
  const moreServicesRef = useRef();
  const contactRef = useRef();

  const sectionRefs = {
    header: headerRef,
    footer: footerRef,
    preLoader: preloaderRef,
    hero: heroRef,
    about: aboutRef,
    services: servicesRef,
    portfolio: portfolioRef,
    moreServices: moreServicesRef,
    contact: contactRef,
  };

  return (
    <React.Fragment>
      <Routes>
        <Route
          exact
          path="/"
          element={<Home scroll={scroll} refs={sectionRefs} />}
        />
        <Route exact path="/coming-soon" element={<ComingSoonPage />} />
        <Route exact path="/not-found" element={<NotFoundPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </React.Fragment>
  );
}

export default App;
