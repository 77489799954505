import React from "react";
import "./secondaryBtn.css";

function SecondaryBtn({ text, refs, section }) {
  const handlePageScroll = (section) => (e) => {
    e.preventDefault();
    window.scrollTo(
      0,
      refs[section].current.offsetTop - refs.header.current.offsetHeight
    );
  };
  return (
    <a
      href="index.html"
      className="btn-second"
      onClick={handlePageScroll(section)}
    >
      {text}
    </a>
  );
}

export default SecondaryBtn;
