import React, { useEffect } from "react";
import "./comingSoonPage.css";
import logo from "../assets/logo_pink.png";

function ComingSoonPage() {
  useEffect(() => {
    setTimeout(() => {
      window.location.replace("/");
    }, 3000);
  }, []);

  return (
    <section id="coming-soon">
      <div className="container-fluid">
        <div className="row justify-content-center align-items-center">
          <div className="col-xl-12 d-flex align-items-center justify-content-center">
            <a href="/" className="logo">
              <img src={logo} alt="" className="img-fluid" />
            </a>
          </div>
        </div>

        <div className="row d-flex justify-content-center align-items-center">
          <div className="content col-xl-12">
            <h3>Online Booking is</h3>
            <h2>Coming Soon...</h2>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ComingSoonPage;
