import React, { useState } from 'react';
import './nav.css';

function Nav({ scroll, refs }) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const handleToggleMenu = () => {
    setToggleMenu(!toggleMenu);
  };

  const handleActiveNav = (scroll, currentSection, nextSection) => {
    if (
      scroll > 0 &&
      scroll >=
        refs[currentSection].current.offsetTop -
          refs.header.current.offsetHeight &&
      scroll <
        refs[nextSection].current.offsetTop - refs.header.current.offsetHeight
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handlePageScroll = section => e => {
    e.preventDefault();
    window.scrollTo(
      0,
      refs[section].current.offsetTop - refs.header.current.offsetHeight + 1
    );
  };

  return (
    <nav id="navbar" className={`navbar ${toggleMenu ? 'navbar-mobile' : ''}`}>
      <ul>
        <li>
          <a
            className={`nav-link scrollto ${
              scroll === 0 ||
              (scroll > 0 &&
                scroll <
                  refs.about.current.offsetTop -
                    refs.header.current.offsetHeight)
                ? 'active'
                : undefined
            }`}
            onClick={() => window.scrollTo(0, 0)}
          >
            <i className="bi bi-house-fill"></i>
          </a>
        </li>
        <li>
          <a
            className={`nav-link scrollto ${
              handleActiveNav(scroll, 'about', 'services')
                ? 'active'
                : undefined
            }`}
            onClick={() =>
              window.scrollTo(
                0,
                refs.hero.current.offsetHeight -
                  refs.header.current.offsetHeight +
                  16
              )
            }
          >
            About
          </a>
        </li>
        <li>
          <a
            className={`nav-link scrollto ${
              handleActiveNav(scroll, 'services', 'portfolio')
                ? 'active'
                : undefined
            }`}
            onClick={handlePageScroll('services')}
          >
            Services & Pricing
          </a>
        </li>
        <li>
          <a
            className={`nav-link scrollto ${
              handleActiveNav(scroll, 'portfolio', 'moreServices')
                ? 'active'
                : undefined
            }`}
            onClick={handlePageScroll('portfolio')}
          >
            Gallery
          </a>
        </li>
        {/* <li>
          <a className="nav-link scrollto" href="#team">
            Team
          </a>
        </li> */}
        <li>
          <a
            className={`nav-link scrollto ${
              handleActiveNav(scroll, 'contact', 'footer')
                ? 'active'
                : undefined
            }`}
            onClick={handlePageScroll('contact')}
          >
            Contact
          </a>
        </li>
        {/* <li>
          <a
            target="_blank"
            href="https://www.fresha.com/a/buen-hair-saint-lucia-242-hawken-drive-k3tztpne/booking?menu=true"
            className="getstarted"
          >
            Online Booking
          </a>
        </li> */}
      </ul>
      <i
        className={`bi ${toggleMenu ? 'bi-x' : 'bi-list'} mobile-nav-toggle`}
        onClick={handleToggleMenu}
      ></i>
    </nav>
  );
}

export default Nav;
