import portfolioImg1 from '../assets/portfolio/gallery-1.jpg';
import portfolioImg2 from '../assets/portfolio/gallery-6.jpg';
import portfolioImg3 from '../assets/portfolio/gallery-3.jpg';
import portfolioImg4 from '../assets/portfolio/gallery-4.jpg';
import portfolioImg5 from '../assets/portfolio/gallery-7.jpg';
import portfolioImg6 from '../assets/portfolio/gallery-2.jpg';
import portfolioImg7 from '../assets/portfolio/gallery-5.jpg';
import portfolioImg8 from '../assets/portfolio/gallery-8.jpg';
import portfolioImg10 from '../assets/portfolio/gallery-10.jpg';

const filterData = [
  {
    id: 1,
    filter: '*',
    name: 'All',
    active: true,
  },
  {
    id: 2,
    filter: 'filter-women',
    name: 'Women',
    active: false,
  },
  {
    id: 3,
    filter: 'filter-men',
    name: 'Men',
    active: false,
  },
  {
    id: 4,
    filter: 'filter-design',
    name: 'Design',
    active: false,
  },
];

const portfolioData = [
  {
    id: 1,
    filter: 'filter-design',
    imgUrl: portfolioImg1,
    imgAlt: 'Stylish coiffure',
    title: 'Stylish coiffure',
    content: 'Playful and dynamic',
  },
  {
    id: 2,
    filter: 'filter-men',
    imgUrl: portfolioImg2,
    imgAlt: 'Hip haircut',
    title: 'Hip haircut',
    content: 'Sharp and edgy',
  },
  {
    id: 3,
    filter: 'filter-women',
    imgUrl: portfolioImg3,
    imgAlt: 'Fashion-forward hairstyle',
    title: 'Fashion-forward hairstyle',
    content: 'Unique and eye-catching',
  },
  {
    id: 4,
    filter: 'filter-women',
    imgUrl: portfolioImg4,
    imgAlt: 'Fresh and modern hairstyle',
    title: 'Fresh and modern hairstyle',
    content: 'Stylish and on point',
  },
  {
    id: 5,
    filter: 'filter-women',
    imgUrl: portfolioImg5,
    imgAlt: 'Trendy hairdo',
    title: 'Trendy hairdo',
    content: 'Cool and confident',
  },
  {
    id: 6,
    filter: 'filter-design',
    imgUrl: portfolioImg6,
    imgAlt: 'Bold and vibrant hairdo',
    title: 'Bold and vibrant hairdo',
    content: 'Quirky and unconventional',
  },
  {
    id: 7,
    filter: 'filter-men',
    imgUrl: portfolioImg7,
    imgAlt: 'Dapper hairdo',
    title: 'Dapper hairdo',
    content: 'Cutting-edge and fashionable',
  },
  {
    id: 8,
    filter: 'filter-women',
    imgUrl: portfolioImg8,
    imgAlt: 'Hair on fleek',
    title: 'Hair on fleek',
    content: 'Fresh and contemporary',
  },
  {
    id: 9,
    filter: 'filter-women',
    imgUrl: portfolioImg10,
    imgAlt: 'Effortlessly cool locks',
    title: 'Effortlessly cool locks',
    content: 'Urban and street-smart',
  },
];

export { portfolioData, filterData };
