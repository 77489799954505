import React from "react";
import Nav from "./Nav";
import "./header.css";
import logo from "../assets/logo_pink.png";

function Header({ scroll, refs }) {
  return (
    <header
      id="header"
      ref={refs.header}
      className={`fixed-top d-flex align-items-center header-transparent ${
        scroll > 100 ? "header-scrolled" : undefined
      }`}
    >
      <div className="container-fluid">
        <div className="row justify-content-center align-items-center">
          <div className="col-xl-11 d-flex align-items-center justify-content-between">
            <a href="/" className="logo">
              <img src={logo} alt="" className="img-fluid" />
            </a>
            <Nav scroll={scroll} refs={refs} />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
