import React from "react";
import "./intro.css";
import introImg from "../assets/intro-1.jpg";
import SecondaryBtn from "./SecondaryBtn";

function Intro({ refs }) {
  return (
    <section id="about" className="about" ref={refs.about}>
      <div className="container">
        <div className="row content" data-aos="zoom-out" data-aos-delay="200">
          <div className="col-lg-6 mb-5 mb-lg-0">
            <div className="img-years">
              <img src={introImg} alt="Image" className="img-fluid" />
              <div className="year">
                <span>
                  3{" "}
                  <span>
                    years in <br />
                    excellent service
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 ml-auto pl-lg-5 text-center">
            <h2 className="scissors text-center">
              Welcome To <br />
              BUEN Salon! <br />
              <i className="fa fa-scissors" aria-hidden="true"></i>
            </h2>

            <p className="my-5 lead">
              We are passionate about hair styling and cutting and strongly
              believe fabulous hair style changes to a better life. BUEN hair
              salon is located at St. Lucia to offer contemporary hair service
              to all its clients. At BUEN, we provide cut, colour, perm, hair
              styling, keratin, hair extensions plus fantastic hair and scalp
              treatments.
            </p>
            <p>
              <SecondaryBtn
                text={"Learn More"}
                refs={refs}
                section={"services"}
              />
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Intro;
