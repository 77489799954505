import React, { useEffect, useState, useRef } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./gallery.css";
import Isotope from "isotope-layout";
import Glightbox from "glightbox";
import { portfolioData, filterData } from "../data/galleryData";

function Gallery({ refs }) {
  const isotope = useRef();
  const [filterKey, setFilterKey] = useState("*");
  const [filter, setFilter] = useState(filterData);
  const [gallery, setGallery] = useState(portfolioData);

  new Glightbox({
    selector: ".portfolio-lightbox",
  });

  useEffect(() => {
    setGallery(portfolioData);
  }, []);

  useEffect(() => {
    isotope.current = new Isotope(".portfolio-container", {
      itemSelector: ".portfolio-item",
      layoutMode: "masonry",
    });
    // cleanup
    return () => (isotope.current = null);
  }, []);

  useEffect(() => {
    filterKey === "*"
      ? isotope.current.arrange({ filter: `*` })
      : isotope.current.arrange({ filter: `.${filterKey}` });
  }, [filterKey]);

  const handleFilterKeyChange = (key, id) => {
    setFilterKey(key);
    const newFilter = filter.map((item) => {
      item.active = false;
      if (item.id === id) item.active = true;
      return item;
    });
    setFilter(newFilter);
  };
  return (
    <section id="portfolio" className="portfolio" ref={refs.portfolio}>
      <div className="container">
        <div
          className="row justify-content-center mb-5"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div className="col-md-7 text-center">
            <h2 className="scissors text-center">
              Gallery
              <br />
              <i className="fa fa-scissors" aria-hidden="true"></i>
            </h2>
            <p className="mb-5 lead">
              Collection of photographs that showcase our past work, including
              haircuts, hairstyles, coloring, and more. From classic styles to
              the latest trends, our team is dedicated to providing you with the
              perfect look that suits your personal style.
            </p>
          </div>
        </div>

        <div className="row" data-aos="fade-up" data-aos-delay="200">
          <div className="col-lg-12 d-flex justify-content-center">
            <ul id="portfolio-flters">
              {filter.map((item) => (
                <li
                  key={item.id}
                  data-filter={item.filter}
                  className={item.active ? "filter-active" : undefined}
                  onClick={() => handleFilterKeyChange(item.filter, item.id)}
                >
                  {item.name}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div
          className="row portfolio-container"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          {gallery &&
            gallery.map((item) => (
              <div
                key={item.id}
                className={`col-lg-4 col-md-6 portfolio-item ${item.filter}`}
              >
                <div className="portfolio-wrap">
                  <LazyLoadImage
                    src={item.imgUrl}
                    className="img-fluid"
                    alt={item.imgAlt}
                    effect="blur"
                    afterLoad={() => isotope.current.arrange({ filter: "*" })}
                  />
                  <div className="portfolio-info">
                    <h4>{item.title}</h4>
                    <p>{item.content}</p>
                    <div className="portfolio-links">
                      <a
                        href={item.imgUrl}
                        data-gallery="portfolioGallery"
                        className="portfolio-lightbox"
                        title={item.title}
                      >
                        <i className="bx bx-plus"></i>
                      </a>
                      {/* <a
                        href="portfolio-details.html"
                        className="portfolio-details-lightbox"
                        data-glightbox="type: external"
                        title="Portfolio Details"
                      >
                        <i className="bx bx-link"></i>
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
}

export default Gallery;
