import React from 'react';
import ContactForm from './ContactForm';
import './contact.css';

function Contact({ refs }) {
  return (
    <section id="contact" className="contact" ref={refs.contact}>
      <div className="container">
        <div
          className="row justify-content-center mb-5"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div className="col-md-7 text-center">
            <h2 className="scissors text-center">
              Contact
              <br />
              <i className="fa fa-scissors" aria-hidden="true"></i>
            </h2>
            <p className="mb-5 lead">
              Get in Touch, whether you have a question, a comment, or a
              request, we want to hear from you!
            </p>
          </div>
        </div>
      </div>

      <div>
        <iframe
          style={{ border: '0', width: '100%', height: '350px' }}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d884.7379415624132!2d153.0058524873349!3d-27.50187548241921!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b91517948fc4507%3A0x816ad5bfa48e4337!2sBUEN%20Salon!5e0!3m2!1sen!2sau!4v1684381442810!5m2!1sen!2sau"
          frameBorder="0"
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      <div className="container">
        <div className="row ">
          <div
            className="col-lg-6 mt-5"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="row">
              <div className="col-md-12">
                <div className="info-box">
                  <i className="bx bx-map"></i>
                  <h3>Our Address</h3>
                  <p>Shop 2, 242 Hawken Dr, St Lucia QLD 4067</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-box mt-4">
                  <i className="bx bx-time-five"></i>
                  <h3>Working Hours</h3>
                  <p>
                    Monday, Friday: 10am to 5:30pm
                    <br />
                    Tuesday - Thursday: 9am to 5pm
                    <br />
                    Saturday, Sunday: 10am to 4pm
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-box mt-4">
                  <i className="bx bx-phone-call"></i>
                  <h3>Call Us</h3>
                  <p>
                    Mobile Phone
                    <br />
                    (+61) 411 299 998
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-lg-6 mt-5"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <ContactForm />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
