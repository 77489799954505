import React from "react";
import Contact from "./Contact";
import Gallery from "./Gallery";
import Intro from "./Intro";
import MoreServices from "./MoreServices";
import Services from "./Services";

function Main({ refs }) {
  return (
    <main id="main">
      <Intro refs={refs} />
      <Services refs={refs} />
      <Gallery refs={refs} />
      <MoreServices refs={refs} />
      <Contact refs={refs} />
    </main>
  );
}

export default Main;
